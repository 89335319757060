/* Example Styles for React Tags*/
#app {
  padding: 40px;
}

div.ReactTags__tags {
    position: relative;
}

/* Styles for the input */
div.ReactTags__tagInput {
    width: 100%;
    border-radius: 2px;
    display: inline-block;
}
div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
    height: 38px;
    margin: 5px 0;
    font-size: 13px;
    width: 100%;
    border: 1px solid #eee;
    padding: 0 4px;
    color: #222a42;
    border-color: rgba(29, 37, 59, 0.25);
    border-radius: 3px !important;
    padding: 10px 18px 10px 18px;
    outline: none;
}

div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  border: 1px solid #117cfe !important;
}

div.ReactTags__tagInput input.ReactTags__tagInputField:active {
  border-color: #117cfe !important;
}

input::placeholder { color: #a9a9a9; }

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 5px;
    margin: 0 5px;
    cursor: move;
    border-radius: 2px;
}
div.ReactTags__selected a.ReactTags__remove {
    color: #aaa;
    margin-left: 5px;
    cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
    position: absolute;
}
div.ReactTags__suggestions ul {
    list-style-type: none;
    box-shadow: .05em .01em .5em rgba(0,0,0,.2);
    background: white;
    width: 200px;
}
div.ReactTags__suggestions li {
    border-bottom: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0;
}
div.ReactTags__suggestions li mark {
    text-decoration: underline;
    background: none;
    font-weight: 600;
}
div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
    background: #b7cfe0;
    cursor: pointer;
}